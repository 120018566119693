const report1 = {
  heightproblm: {
    height: "90vh",
    overflowY: "scroll",
  },
  aint: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    padding: "10px 0",
    backgroundColor: "rgb(250,250,250)",
    borderRadius: "25px",
  },
  leftside: {
    flex: "",
    padding: "10px",
  },
  middleone: {
    flex: "",
    padding: "10px",
    textAlign: "center",
  },
  josss: {
    color: " rgba(99, 105, 128, 1)",
  },
  fernando: {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18.23px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
  },

  row: {
    display: "flex",
    justifyContent: "",
    marginBottom: "5px",

    gap: "20px",
    padding: "03px",
  },
  button: {
    border: "none",
    backgroundColor: "white",
    color: "blue",
    cursor: "pointer",
  },
  rightside: {
    display: "flex",
    gap: "10px",
    marginRight: "10px",
  },
  curves: {
    height: "8px",
    width: "8px",
    backgroundColor: "rgb(129,227,158)",
    borderRadius: "20px",
    display: "inline-block",
    marginRight: "8px",
  },
  basic: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "4px",
    paddingBottom: "4px",
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgb(129,227,158)",
    borderRadius: "25px",
    gap: "8px",
  },
  bttn: {
    color: "rgb(45,83,218)",
    border: "none",
    backgroundcolor: "rgb(250,250,259)",
    display: "",
    alignItems: "flex",
  },
  clientbtn: {
    marginRight: "20px",
    color: "black",
  },
  customswitch: {
    backgroundColor: "rgb(3,200,62)",

    height: "16px",
    width: "32px",
  },
  switchChecked: {
    backgroundColor: "rgb(3,200,62)",
  },
  switchUnchecked: {
    backgroundColor: "gray",
  },
};
export default report1;
