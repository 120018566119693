import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInfo: {
        userId: "",
        userName: "",
        clientId: "",
        userType: "",
    },
    userList: [],
    departmentList: [],
    locationList: [],
    isLoggedIn: true,
}

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        setIsLoggedIn: (state, action) => {
            return{
                ...state,
                isLoggedIn: action.payload,
            }
        },

        setUserInfo: (state, action) => {
            return {
                ...state,
                userInfo: action.payload,
            };
        },

        setUserList: (state, action) => {
            return {
                ...state,
                userList: action.payload,
            };
        },

        setDepartmentList: (state, action) => {
            return {
                ...state,
                departmentList: action.payload,
            };
        },

        setLocationList: (state, action) => {
            return {
                ...state,
                locationList: action.payload,
            };
        },
    },
});

export
    const
    {
        setIsLoggedIn,
        setUserInfo,
        setUserList,
        setDepartmentList,
        setLocationList
    }
        = authSlice.actions

export const authReducer = authSlice.reducer;