import styled from "styled-components";

const StyledClientWrapper = styled.div`
    .client_totle_count{
        text-wrap: nowrap;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.83px;
        text-align: left;
    }
    .view_client_goback{
        display: flex;
        align-items: center;
        gap: 10px;
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 20.83px;
            text-align: left;
            margin-bottom: 0px;
            cursor: pointer;
        }
        .right_out_icon{
            font-size: 16px;
            // font-weight: 400;
        }
        storng{
            font-size: 16px;
        }
    }
    .form_button_section{
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: end;
        margin-top: 12px;
    }
`;

export default StyledClientWrapper;