import React from "react";
import { Col, Row } from "react-bootstrap";
import BarChart from "../../reusableComponent/Charts/Bar";
import styled from "styled-components";

const DashboardWrap = styled.div`
  .users-details-box {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    flex-direction: column;
    padding: 16px;
    gap: 14px;
    border-radius: 8px;
    border: 0.5px solid #e2e2e8;
    box-shadow: 0px 4px 27px 0px #00000017;
    background: #ffffff;
  }
  .user_details_box_container {
    justify-content: space-between;
  }
  .details_box_title {
    font-size: 18px;
    font-weight: 600;
    line-height: 20.83px;
    text-align: left;
  }
  .user-type-details-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20.83px;
    margin-bottom: 0px !important;
  }
  .users-details-box-col {
    width: 20%;
  }
  .clients-details-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .new-clients-data {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.23px;
    text-align: left;
    color: #1f243b;
  }
  .chart-container{
    width: 100%;
    border-radius: 8px;
    border: 0.5px solid #e2e2e8;
    box-shadow: 0px 4px 27px 0px #00000017;
    background: #ffffff;
  }
  .chart_title{
    padding: 10px 0px 0px 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.83px;
    text-align: left;
  }
  .location_details_titles{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 8px 16px;
    p{
      font-size: 14px;
      font-weight: 500;
      line-height: 18.23px;
      text-align: left;
    }
  }
  .location_details_wrapper{
    height: 254px;
    overflow: auto;
    padding: 0px 10px 8px 16px;
  }
  .location_details_list{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

function Dashboard() {
  // const tabledata = [
  //   {
  //     id: 1,
  //     clientname: "Client Name example 1",
  //     date: "27th Aug 2024",
  //     subscription: "Lite",
  //   },
  //   {
  //     id: 2,
  //     clientname: "Client Name example 2",
  //     date: "27th Aug 2024",
  //     subscription: "Lite",
  //   },
  //   {
  //     id: 3,
  //     clientname: "Client Name example 3",
  //     date: "27th Aug 2024",
  //     subscription: "Lite",
  //   },
  // ];

  // const columns = [
  //   {
  //     title: "Client Name",
  //     dataIndex: "clientname",
  //     key: "clientname",
  //     width: "25%",
  //   },
  //   {
  //     title: "Added Date",
  //     dataIndex: "date",
  //     key: "date",
  //     width: "25%",
  //   },
  //   {
  //     title: "Subscription Model",
  //     dataIndex: "subscription",
  //     key: "subscription",
  //     width: "25%",
  //   },
  //   {
  //     title: "Action",
  //     dataIndex: "action",
  //     key: "action",
  //     width: "25%",
  //   },
  // ];

  const LocationData = [
    {
      key: 1,
      country: "Kenya",
      totalUsers: "3"
    },
    {
      key: 2,
      country: "Tanzania",
      totalUsers: "3"
    },
    {
      key: 3,
      country: "Ethiopia",
      totalUsers: "3"
    },
    {
      key: 4,
      country: "Ethiopia",
      totalUsers: "3"
    },
    {
      key: 4,
      country: "Ethiopia",
      totalUsers: "3"
    },
    {
      key: 4,
      country: "Ethiopia",
      totalUsers: "3"
    },
    {
      key: 4,
      country: "Ethiopia",
      totalUsers: "3"
    },
    {
      key: 4,
      country: "Ethiopia",
      totalUsers: "3"
    },
    {
      key: 4,
      country: "Ethiopia",
      totalUsers: "3"
    },
    {
      key: 4,
      country: "Ethiopia",
      totalUsers: "3"
    },
    {
      key: 4,
      country: "Ethiopia",
      totalUsers: "3"
    },
    {
      key: 4,
      country: "Ethiopia",
      totalUsers: "3"
    },
    {
      key: 4,
      country: "Ethiopia",
      totalUsers: "3"
    },
  ]
  return (
    <>
      <DashboardWrap>
        <Row className="user_details_box_container">
          <Col className="users-details-box-col">
            {/* <h1 className="details_box_title">New</h1> */}
            <div className="users-details-box">
              <div className="clients-details-wrapper">
                <p className="user-type-details-title">New Clients</p>
                <p className="user-type-details-title">30</p>
              </div>
              <span className="new-clients-data">(2) new clients </span>
            </div>
          </Col>
          <Col className="users-details-box-col">
            {/* <h1 className="details_box_title">Lead</h1> */}
            <div className="users-details-box">
              <div className="clients-details-wrapper">
                <p className="user-type-details-title">Lead Clients</p>
                <p className="user-type-details-title">30</p>
              </div>
              <span className="new-clients-data">(2) new clients </span>
            </div>
          </Col>
          <Col className="users-details-box-col">
            {/* <h1 className="details_box_title">Follow-up</h1> */}
            <div className="users-details-box">
              <div className="clients-details-wrapper">
                <p className="user-type-details-title">Follow-up Clients</p>
                <p className="user-type-details-title">30</p>
              </div>
              <span className="new-clients-data">(2) new clients </span>
            </div>
          </Col>
          <Col className="users-details-box-col">
            {/* <h1 className="details_box_title">Active</h1> */}
            <div className="users-details-box">
              <div className="clients-details-wrapper">
                <p className="user-type-details-title">Active Clients</p>
                <p className="user-type-details-title">30</p>
              </div>
              <span className="new-clients-data">(2) new clients </span>
            </div>
          </Col>
          <Col className="users-details-box-col">
            {/* <h1 className="details_box_title">Inactive</h1> */}
            <div className="users-details-box">
              <div className="clients-details-wrapper">
                <p className="user-type-details-title">Inactive Clients</p>
                <p className="user-type-details-title">30</p>
              </div>
              <span className="new-clients-data">(2) new clients </span>
            </div>
          </Col>
        </Row>
        <br></br>
        <Row gutter={6}>
          <Col xl={6}>
            <div className="chart-container">
              <p className="chart_title">Client onboarding count:</p>
              <BarChart />
            </div>
          </Col>
          <Col xl={6}>
            <div className="chart-container"> 
              <p className="chart_title">Client Locations</p>
              
              <div className="location_details_titles">
                <p>Country</p>
                <p>Client Count</p>
              </div>
              <div className="location_details_wrapper">
                {LocationData?.map((item, index) => {
                  return(
                    <div key={index} className="location_details_list">
                      <p>{item.country}</p>
                      <p>{item.totalUsers}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
        {/* <div>
          <p>Recent Client:</p>
          <UITable data={tabledata} columns={columns} />
        </div> */}
      </DashboardWrap>
    </>
  );
}

export default Dashboard;
