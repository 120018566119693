import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UITable from "../../reusableComponent/UITable/UITable";
import { ButtonFilled } from "../../reusableComponent/UIButtons";
import AddNewClient from "./AddNewClient";
import { Table, Button, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"; // Import icons
import report from "./client/style";
import { Tabs } from "antd";
import { Typography } from "antd";
import Details from "./details/details";
import COLORS from "../../Common/Colors";
import styled from "styled-components";
import AddClient from "./addclientpage/addclient";
import StyledClientWrapper from "./style";
const { Title } = Typography;

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #00000024;
    border-radius: 10px;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 600;
    font-size: 0.9rem;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
  .ant-table-thead > tr > th::before {
    display: none !important;
  }
  .ant-table-cell-row-hover {
    background: #e3e8fc !important;
  }
`;

const onChange = (key) => {
  console.log(key);
};
const items = [
  {
    key: "1",
    label: "Active",
    children: "",
  },
  {
    key: "2",
    label: "In Active",
    children: "",
  },
];

function Client() {
  const data = [
    {
      key: "1",
      clientName: "William Sebastin",
      clientId: "bmw1234",
      emailId: "William Sebastin@gmail.com",
      companyName: "Tale Academy ",
      employee: "78",
      subscription: "Basic",
    },
    {
      key: "2",
      clientName: "William Sebastin",
      clientId: "bmw1234",
      emailId: "William Sebastin@gmail.com",
      companyName: "Tale Academy ",
      employee: "78",
      subscription: "Basic",
    },
    {
      key: "2",
      clientName: "William Sebastin",
      clientId: "bmw1234",
      emailId: "William Sebastin@gmail.com",
      companyName: "Tale Academy ",
      employee: "78",
      subscription: "Basic",
    },
    {
      key: "4",
      clientName: "William Sebastin",
      clientId: "bmw1234",
      emailId: "William Sebastin@gmail.com",
      companyName: "Tale Academy ",
      employee: "78",
      subscription: "Basic",
    },
    {
      key: "5",
      clientName: "William Sebastin",
      clientId: "bmw1234",
      emailId: "William Sebastin@gmail.com",
      companyName: "Tale Academy ",
      employee: "78",
      subscription: "Basic",
    },
    {
      key: "6",
      clientName: "William Sebastin",
      clientId: "bmw1234",
      emailId: "William Sebastin@gmail.com",
      companyName: "Tale Academy ",
      employee: "78",
      subscription: "Basic",
    },
  ];

  const handleEdit = (clientId) => {
    console.log(`Editing client with ID: ${clientId}`);
  };

  const handleDelete = (clientId) => {
    // Handle delete action
    console.log(`Deleting client with ID: ${clientId}`);
  };

  const columns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      title: "Client ID",
      dataIndex: "clientId",
      key: "clientId",
    },
    {
      title: "Email ID",
      dataIndex: "emailId",
      key: "emailId",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div
          style={report.active}
          onMouseEnter={(e) => {
            e.currentTarget.querySelector(
              ".action-container"
            ).style.opacity = 1;
            e.currentTarget.querySelector(
              ".action-container"
            ).style.pointerEvents = "auto";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.querySelector(
              ".action-container"
            ).style.opacity = 0;
            e.currentTarget.querySelector(
              ".action-container"
            ).style.pointerEvents = "none";
          }}
        >
          <div className="action-container" style={report.actionContainer}>
            <Space size="middle">
              <Button
                onClick={() => handleEdit(record.clientId)}
                type="primary"
                icon={<EditOutlined />}
                shape="circle"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  color: "blue",
                }}
              />
              <Button
                onClick={() => handleDelete(record.clientId)}
                type="danger"
                icon={<DeleteOutlined />}
                shape="circle"
                style={{ border: "none", color: "blue" }}
              />
            </Space>
          </div>
        </div>
      ),
    },
  ];
  const [selectedRow, setSelectedRow] = useState(null);
  const [newAddClient, AddNewClient] = useState(false);
  const [viewClientData, setViewClientData] = useState(false)

  const navigate = useNavigate();

  const handleClicknavi = () => {
    navigate("/client/addclient", { replace: true });
  };

  return (
    <>
      <StyledClientWrapper>
        {viewClientData ? (
          <>
            <Details setViewClientData={setViewClientData}/>
          </>
        ) : (
          <>
            <div>
              <Title level={3}>Client </Title>
            </div>

            <div>
              <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>

            <div style={report.no}>
              <div style={report.totalno}>
                <Title className="client_totle_count" level={3}>Total no: 6 </Title>

                <div style={report.addclient}>
                  <Button
                    style={report.meadd}
                    onClick={handleClicknavi}
                    type="primary"
                  >
                    Add Client{" "}
                  </Button>
                </div>
              </div>
            </div>

            <div style={report.table}>
              <StyledTable
                // rowSelection={rowSelection}
                dataSource={data}
                columns={columns}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "" : "non-white"
                }
                rowKey="checkin_id"
                id="Table"
                // onChange={onChangeSortedTable}
                showSorterTooltip={{
                  target: "sorter-icon",
                }}
                onRow={(record, rowIndex) => ({
                  onClick: () => {setSelectedRow(record); setViewClientData(true)},
                  // onMouseEnter: () => setShowTableHover(rowIndex),
                  // onMouseLeave: () => setShowTableHover(null),
                })}
              />
            </div>
          </>
        )}
      </StyledClientWrapper>
    </>
  );
}

export default Client;
