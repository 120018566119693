import React from "react";
import { ReactComponent as Dashboard } from "../../src/assets/Icons/dashboard.svg";
import { ReactComponent as Home } from "../../src/assets/Icons/home.svg";
import { ReactComponent as User } from "../../src/assets/Icons/people.svg";

const styleIcon = {
  height: '20px',
  width: '20px'
}

const iconTypes = {
  dashboard: Dashboard,
  home: Home,
  client: User
};

const Icon = ({ name, ...props }) => {
  let Icon = iconTypes[name];
  if (!Icon) {
    console.error(`Icon with name "${name}" does not exist in iconTypes.`);
    return null; // Return null to avoid rendering an invalid component
  }
  return <Icon style={styleIcon} {...props} />;
};
export default Icon;
