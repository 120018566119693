import React, { useState } from "react";
import styled from "styled-components";
import GridBuilder from "../../UIBuilderEngine/GridBuilder";
import { ButtonFilled } from "../../reusableComponent/UIButtons";
import { Button, Switch, Table } from "antd";
import UITable from "../../reusableComponent/UITable/UITable";
import { RightOutlined } from "@ant-design/icons";
import StyledClientWrapper from "./style";
import StepsProgress from "../../reusableComponent/StepProgress/StepsProgress";
import COLORS from "../../Common/Colors";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #00000024;
    border-radius: 10px;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 600;
    font-size: 0.9rem;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
  .ant-table-thead > tr > th::before {
    display: none !important;
  }
  .ant-table-cell-row-hover {
    background: #e3e8fc !important;
  }
`;
const AddClientWrap = styled.div`
  h6 {
    color: #636980;
  }
`;

const dataSource = [
  {
    key: 1,
    clientName: "Dashboard (Mine, My Team, HR)",
    pricing: "",
    availableIn: "Basic, Lite, Pro",
    access: false,
  },
  {
    key: 2,
    clientName: "Check in and check out through Biometrics",
    pricing: "",
    availableIn: "Basic, Lite, Pro",
    access: false,
  },
  {
    key: 3,
    clientName: "Leave application through portal",
    pricing: "",
    availableIn: "Basic, Lite, Pro",
    access: false, // Toggle button is off
  },
  {
    key: 4,
    clientName: "Calendar view (Mine, Team, All)",
    pricing: "",
    availableIn: "Basic, Lite, Pro",
    access: false,
  },
]

const AddNewClient = ({ setAddNew }) => {
  
  const [ispermission, setIsPermission] = useState(false);
  const [clientPermissionData, setClientPermissionData] = useState(dataSource)
  const tabledata = [
    {
      id: 1,
      featurename: "Dashboard(Mine, My Team, HR)",
      pricing: "-",
      subscription: "Basic | Lite | Pro",
    },
    {
      id: 2,
      featurename: "Check in and Check out through Biometrics",
      pricing: "-",
      subscription: "Lite | Pro",
    },
    {
      id: 3,
      featurename: "Leave application through portal",
      pricing: "-",
      subscription: "Lite",
    },
  ];

  const columns = [
    {
      title: "Feature name",
      dataIndex: "featurename",
      key: "featurename",
      width: "25%",
    },
    {
      title: "Pricing",
      dataIndex: "pricing",
      key: "pricing",
      width: "25%",
    },
    {
      title: "Available in Subscription Model",
      dataIndex: "subscription",
      key: "subscription",
      width: "25%",
    },
    {
      title: "Access",
      dataIndex: "action",
      key: "action",
      width: "25%",
      render: (action, record) => {
        return <Switch size="small" />;
      },
    },
  ];

  const SubcriptionPlanData = [
    {value: "basic", label: "Basic"},
    {value: "lite", label: "Lite"},
    {value: "pro", label: "Pro"},
  ]

  const addNewClientForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [],
        fields: [
          [
            {
              id: 1,
              label: "Client Name:",
              type: "text",
              name: "name",
              Placeholder: "",
            },
            {
              id: 2,
              label: "Contact Person:",
              type: "text",
              name: "contactperson",
              Placeholder: "",
            },
          ],
          [
            {
              id: 3,
              label: "Contact No:",
              type: "text",
              name: "contactname",
              Placeholder: "",
            },
            {
              id: 4,
              label: "Email ID:",
              type: "text",
              name: "email",
              Placeholder: "",
            },
          ],
          [
            {
              id: 5,
              label: "Company Size:",
              type: "text",
              name: "companysize",
              Placeholder: "",
            },
            {
              id: 6,
              label: "Industry:",
              type: "text",
              name: "industry",
              Placeholder: "",
            },
          ],
          [
            {
              id: 7,
              label: "KRA Pin number of the company:",
              type: "text",
              name: "krapin",
              Placeholder: "",
            },
            {
              id: 8,
              label: "Certificate of incorporation:",
              type: "file",
              name: "certificate",
              Placeholder: "",
            },
          ],
          [
            {
              id: 9,
              label: "Subscription Type:",
              type: "select",
              name: "subscriptiontype",
              options: SubcriptionPlanData,
              Placeholder: "",
            },
            {
              id: 10,
              label: "Billing Cycle:",
              type: "text",
              name: "billingcycle",
              Placeholder: "",
            },
          ],
          [
            {
              id: 11,
              label: "Billing Amount:",
              type: "text",
              name: "billingAmount",
              Placeholder: "",
            },
            {
              id: 10,
              label: "",
              type: "div",
              name: "",
              Placeholder: "",
            },
          ],
        ],
      },
    ],
  };

  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Feautures" },
  ];

  console.log("data--->", data)

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitForm = (e) => {
    setStep(1);
  };

  const handleSelectChange = (value, name) => {
    console.log('Selected value:', value);
    console.log('Field name:', name);
    // Handle the value change logic here
  };

  const handleSwitchChange = (key, checked) => {  
    setClientPermissionData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === key ? { ...item, access: checked } : item
      );
      return updatedData;
    });
  };
  

  const columns1 = [
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      title: "Pricing",
      dataIndex: "pricing",
      key: "pricing",
      render: (text) => (text === "" ? "--" : text),
    },
    {
      title: "Available In",
      dataIndex: "availableIn",
      key: "availableIn",
    },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
      render: (text, record) => (
        <Switch
          className="switch_button"
          defaultChecked
          name={record.clientName}
          size="small"
          checked={record.access}
          onChange={(checked) => handleSwitchChange(record.key, checked)}
        />
      ),
    },
  ];

  return (
    <StyledClientWrapper>
      {ispermission ? (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => setIsPermission(false)}
          ></label>{" "}
          <b className="pb-0 pt-1">Select Permission</b>
          <UITable data={tabledata} columns={columns} />
          <div className="text-right w-100 p-1">
            <ButtonFilled
              className="text-end"
              type="primary"
              onClick={() => setIsPermission(true)}
            >
              Create
            </ButtonFilled>
          </div>
        </>
      ) : (
        <>
          <div>
            <div>
              <h5 className="view_client_goback">
                <p>Client</p> <RightOutlined className="right_out_icon" />{" "}
                <storng>Adding Client</storng>{" "}
              </h5>
            </div>
            <StepsProgress stepdata={stepdata} title="Add Role:" step={step} />
            {step === 0 &&
              <>
                <GridBuilder 
                  formStructureJson={addNewClientForm} 
                  formSubmit={handleSubmitForm}
                  handleChange={handleFormChange}
                  handleSelectChange={handleSelectChange}
                  initialValues={data}
                />
                <div className="text-right w-100 form_button_section">
                  <Button
                    className="text-end"
                    // onClick={() => setStep(0)}
                  >
                    Cancel
                  </Button>
                  <ButtonFilled
                    className="text-end"
                    type="primary"
                    onClick={() => setStep(1)}
                  >
                    Next
                  </ButtonFilled>
                </div>
              </>
            }

            {step === 1 &&
              <>
                <StyledTable
                  dataSource={clientPermissionData}
                  columns={columns1}
                  rowClassName={() => "custom-row"}
                  pagination={false}
                />

                <div className="text-right w-100 form_button_section">
                  <Button
                    className="text-end"
                    onClick={() => setStep(0)}
                  >
                    Back
                  </Button>
                  <ButtonFilled
                    className="text-end"
                    type="primary"
                    // onClick={() => setStep(1)}
                  >
                    Create
                  </ButtonFilled>
                </div>
              </>
            }
          </div>
        </>
      )}
    </StyledClientWrapper>
  );
};

export default AddNewClient;
