import React from "react";
import report1 from "../client/detailstyle/report1";
import { RightOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { Table } from "antd";
import { Switch } from "antd";
import "react-phone-number-input/style.css";

console.log(report1);
const onChange = (key) => {
  console.log(key);
};

const items = [
  {
    key: "1",
    label: "Feautures",
    children: "",
  },
  {
    key: "2",
    label: "Client Contract",
    children: "",
  },
];

const subscriptiontype = ["Basic", "Lite", "Pro"];

const sunbscriptionFrequncey = ["yearly", "Monthly"];
const state = ["New", "Lead", "Follow Up ", "Active", "InActive"];

const Details = ({setViewClientData}) => {
  const dataSource = [
    {
      key: "1",
      clientName: "Client A",
      pricing: "--",
      availableIn: "USA",
      access: false,
    },
    {
      key: "2",
      clientName: "Client B",
      pricing: "--",
      availableIn: "Canada",
      access: false,
    },
    {
      key: "3",
      clientName: "Client C",
      pricing: "--",
      availableIn: "Canada",
      access: false, // Toggle button is off
    },
    {
      key: "4",
      clientName: "Client D",
      pricing: "--",
      availableIn: "Canada",
      access: false,
    },
    // {
    //   key: "5",
    //   clientName: "Client E",
    //   pricing: "--",
    //   availableIn: "Canada",
    //   access: false, // Toggle button is off
    // },
    // {
    //   key: "6",
    //   clientName: "Client F",
    //   pricing: "--",
    //   availableIn: "Canada",
    //   access: false, // Toggle button is off
    // },
    // {
    //   key: "7",
    //   clientName: "Client G",
    //   pricing: "--",
    //   availableIn: "Canada",
    //   access: false, // Toggle button is off
    // },
    // {
    //   key: "8",
    //   clientName: "Client H",
    //   pricing: "--",
    //   availableIn: "Canada",
    //   access: false, // Toggle button is off
    // },
    // {
    //   key: "9",
    //   clientName: "Client I",
    //   pricing: "--",
    //   availableIn: "Canada",
    //   access: false, // Toggle button is off
    // },
    // {
    //   key: "10",
    //   clientName: "Client J",
    //   pricing: "--",
    //   availableIn: "Canada",
    //   access: false, // Toggle button is off
    // },
    // {
    //   key: "11",
    //   clientName: "Client K",
    //   pricing: "--",
    //   availableIn: "Canada",
    //   access: false, // Toggle button is off
    // },
    // {
    //   key: "12",
    //   clientName: "Client L",
    //   pricing: "--",
    //   availableIn: "Canada",
    //   access: false,
    // },
    // {
    //   key: "13",
    //   clientName: "Client M",
    //   pricing: "--",
    //   availableIn: "Canada",
    //   access: false, // Toggle button is off
    // },
    // {
    //   key: "14",
    //   clientName: "Client N",
    //   pricing: "--",
    //   availableIn: "Canada",
    //   access: false, // Toggle button is off
    // },
    // {
    //   key: "15",
    //   clientName: "Client 0",
    //   pricing: "--",
    //   availableIn: "Canada",
    //   access: false, // Toggle button is off
    // },
  ];

  const columns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      title: "Pricing",
      dataIndex: "pricing",
      key: "pricing",
    },
    {
      title: "Available In",
      dataIndex: "availableIn",
      key: "availableIn",
    },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
      render: (access) => (
        <Switch
          style={{
            ...report1.switch,
            ...(access ? report1.switchChecked : report1.switchUnchecked),
          }}
          defaultChecked
          onChange={onChange}
        />
      ),
    },
  ];

  const handleEdit = () => {
    console.log("am clicked ");
  };

  const handleDelete = () => {
    console.log("am clicked ");
  };
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };
  const addNewClientForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [],
        fields: [
          [
            {
              id: 1,
              label: "Company Name:",
              type: "text",
              name: "name",
              Placeholder: "Airbnb",
            },
            {
              id: 2,
              label: "Client Name:",
              type: "text",
              name: "clientname",
              Placeholder: "Willi",
            },
          ],
          [
            {
              id: 3,
              label: "Location",
              type: "text",
              name: "location",
              Placeholder: "Nairobi",
            },
            {
              id: 4,
              label: "Employee Count / Company Size ",
              type: "text",
              name: "employeecount",
              Placeholder: "150",
            },
          ],
          [
            {
              id: 5,
              label: "Contact Person",
              type: "text",
              name: "contactperson",
              Placeholder: "smithi",
            },
            {
              id: 6,
              label: "Contact No",
              type: "text",
              name: "contactno",
              Placeholder: "987654321",
            },
          ],
          [
            {
              id: 7,
              label: "KRA Pin number of the company:",
              type: "text",
              name: "krapin",
              Placeholder: "",
            },
            {
              id: 8,
              label: "Certificate of incorporation:",
              type: "file",
              name: "certificate",
              Placeholder: "",
            },
          ],
          [
            {
              id: 9,
              label: "Email",
              type: "email",
              name: "email",
              Placeholder: "will@gmail.com",
            },
            {
              id: 10,
              label: "Password",
              type: "password",
              name: "password",
              Placeholder: "12345678",
            },
          ],
          [
            {
              id: 11,
              label: "Subscription Plan",
              type: "select",
              name: "subscriptiontype",
              Placeholder: "",
              options: subscriptiontype,
            },
            {
              id: 12,
              label: "Amount($450)",
              type: "text",
              name: "amount",
              Placeholder: "450",
            },
          ],
          [
            {
              id: 13,
              label: "Subcription Frequncy",
              type: "select",
              name: "subcriptionfrequencey",
              Placeholder: "",
              options: sunbscriptionFrequncey,
            },
            {
              id: 14,
              label: "Status",
              type: "select",
              name: "status",
              Placeholder: "",
              // options: 'state'
            },
          ],
          [
            {
              id: 15,
              label: "Attach Client Contract",
              type: "file",
              name: "attachclientcontract",
            },
            {
              id: 16,
            },
          ],
        ],
      },
    ],
  };

  return (
    <>
      <div style={report1.heightproblm}>
        <div>
          <h5 style={report1.clientbtn} className="view_client_goback">
            <p onClick={() => setViewClientData(false)}>Client</p> <RightOutlined className="right_out_icon"/> <storng>Details</storng>{" "}
          </h5>
        </div>

        <div style={report1.aint}>
          <div style={report1.leftside}>
            <div style={report1.row}>
              <span style={report1.josss}>Client Name</span>
              <b style={report1.fernando}>Joseph Fernandaz</b>
            </div>
            <div style={report1.row}>
              <span style={report1.josss}>Location </span>
              <b style={report1.fernando}>Nairobi</b>
            </div>
            <div style={report1.row}>
              <span style={report1.josss}>Client Name</span>
              <b style={report1.fernando}>smith</b>
            </div>
            <div style={report1.row}>
              <span style={report1.josss}>Client Name</span>
              <b style={report1.fernando}>Joseph Will</b>
            </div>
            <div style={report1.row}>
              <span style={report1.josss}>Client Name</span>
              <b style={report1.fernando}>Joseph Fernandaz</b>
            </div>
          </div>

          <div style={report1.middleone}>
            <div style={report1.row}>
              <span style={report1.josss}>Client Id</span>
              <b style={report1.fernando}>BMW1234</b>
            </div>
            <div style={report1.row}>
              <span style={report1.josss}>Employee Count</span>
              <b style={report1.fernando}>150</b>
            </div>
            <div style={report1.row}>
              <span style={report1.josss}>Contact No</span>
              <b style={report1.fernando}>98765443</b>
            </div>
            <div style={report1.row}>
              <span style={report1.josss}>Password</span>
              <b style={report1.fernando}>123456789</b>
            </div>
          </div>

          <div style={report1.rightside}>
            <div style={report1.basic}>
              &nbsp;&nbsp;
              <span style={report1.curves}></span>Basic
            </div>

            <i style={report1.bttn}>
              <EditOutlined />
            </i>
            <i style={report1.bttn}>
              <DeleteOutlined />
            </i>
          </div>
        </div>

        <div>
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            tabBarStyle={{ borderBottom: "none" }}
          />
        </div>

        <div style={report1.scrollable}>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowClassName={() => "custom-row"}
            pagination={false}
          />
        </div>
      </div>
    </>
  );
};

export default Details;
