import Dashboard from "../Pages/Dashboard/Dashboard";
import Client from "../Pages/Client/Client";
import AddNewClient from "../Pages/Client/AddNewClient";

const unProtectedRoutes = [
  {
    path: "/",
    component: <>Login</>,
  },
  {
    path: "/login",
    component: <>Login</>,
  },
];

const protectedRoutes = [
  {
    path: "dashboard",
    title: "Dashboard",
    key: "dashboard",
    component: <Dashboard />,
  },
  {
    path: "client",
    title: "client",
    key: "client",
    component: <Client />,
  },
  {
    path: "client/addclient",
    title: "Addclient",
    key: "addclient",
    component: <AddNewClient />,
  },
];

export const routesData = { unProtectedRoutes, protectedRoutes };
