import React from 'react'
import styled from 'styled-components';
import LogoIcon from "../../assets/Tymeplus HR 1.png";
import {ReactComponent as ProfileImg} from "../../assets/Icons/profile-icon.svg";

const HeaderMenuWrap = styled.div`
    .header-container{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 8px 6px 10px;
        background: rgb(255, 255, 255);
        border-bottom: 1px solid rgb(240, 240, 240);
        // margin-bottom: 12px;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        img{
            width: 100px;
            cursor: pointer
        }
    }
    .profile-icon{
        cursor: pointer
    }
`;

const HeaderBox = () => {
    return(
        <>
            <HeaderMenuWrap>
                <div className='header-container'>
                    <img src={LogoIcon} alt="logo-image" className="header_logo_title" />
                    <div>
                        <ProfileImg className='profile-icon'/>
                    </div>
                </div>
            </HeaderMenuWrap>
        </>
    )
}

export default HeaderBox