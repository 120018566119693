

const report = {
    active: {
       MarginTop : '20px',
       display:'flex',
       gap: '20px'
    }, 
nactivebtn: {
padding: "25px",
 border: 'none', 
 color: 'blue'
    }, 
    no: {
    }, 
    totalno: {
         display: 'flex',
    }, 
    addclient: {
 display: 'flex',
 justifyContent: 'flex-end', 
 width: '90%',



}, meadd: {
    paddingLeft: '45px',  
    paddingRight: '45px', 
    backgroundColor:'rgb(45,83,218)'
  },
  actionContainer: {
    opacity: 0,
    pointerEvents: 'none', 
    display: 'flex',
    justifyContent: 'space-around',
    gap: '10px',
    transition: 'opacity 0.3s ease', 
  },
  actionContainerHover: {
    opacity: 1,
    pointerEvents: 'auto', 
  }, table: {
    MarginBottom: '65px'
  }, 
}




export default report