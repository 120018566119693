
import { configureStore } from '@reduxjs/toolkit';
import { systemReducer } from './systemSlice';
import { authReducer } from './AuthSlice';


const store = configureStore({
    reducer: {
        authTracking: authReducer,
        systemTracking: systemReducer,
    },
});

export default store;
