import React from 'react';
import { Column } from '@ant-design/charts';
import styled from 'styled-components';

const BarchartStyle = styled.div`
  .barchart-container{
    width: 100%;
  }
`;

const BarChart = () => {
  const data = [
    { month: 'Jan', value: 3 },
    { month: 'Feb', value: 4 },
    { month: 'Mar', value: 3.5 },
    { month: 'April', value: 5 },
    { month: 'May', value: 4.9 },
    { month: 'Jun', value: 6 },
    { month: 'Jul', value: 7 },
    { month: 'Aug', value: 9 },
    { month: 'Sep', value: 13 },
    { month: 'Oct', value: 13 },
    { month: 'Nov', value: 13 },
    { month: 'Dec', value: 13 },
  ];

  const config = {
    data,
    height: 300,
    xField: 'month',
    yField: 'value',
    style: {
      fill: ({ type }) => {
        if (type === '10-30分' || type === '30+分') {
          return '#22CBCC';
        }
        return '#2989FF';
      },
    },
    label: {
      text: (originData) => {
        const val = parseFloat(originData.value);
        if (val < 0.05) {
          return (val * 100).toFixed(1) + '%';
        }
        return '';
      },
      offset: 10,
    },
    legend: false,
  };
  return (
    <BarchartStyle>
      <div className='barchart-container'>
        <Column {...config} />
      </div>
    </BarchartStyle>
  )
};

export default BarChart;
