import React from "react"
import { ReactComponent as Dashboard } from "../../assets/Icons/dashboard.svg?react"
import { ReactComponent as HomeIcon }  from "../../assets/Icons/home.svg?react"
// import { ReactComponent as Logout } from "../../../assets/Icons/logout.svg?react"
// import { ReactComponent as Settings } from "../../../assets/Icons/settings.svg?react"
// import { ReactComponent as Permission } from "../../../assets/Icons/permission.svg?react"
import { ReactComponent as People } from "../../assets/Icons/people.svg?react"
// import { ReactComponent as Branch } from "../../../assets/Icons/Branch.svg?react"
// import { ReactComponent as Course } from "../../../assets/Icons/course.svg?react"
// import {SettingOutlined, BranchesOutlined } from "@ant-design/icons"
// import Diversity3Icon from '@mui/icons-material/Diversity3';

const styleIcon = {
  height: "20px",
  width: "20px",
  marginRight: "5px",
}

const iconTypes = {
  dashboard: Dashboard,
  // settings: Settings,
  home: HomeIcon,
  // logout: Logout,
  // permission: Permission,
  people: People,
  // appStore: Settings,
  // branch: Branch,
  // course: Course,
}

const Icon = ({ name }) => {
  return iconTypes[name] ? React.createElement(iconTypes[name], { style: styleIcon }) : null
}

export default Icon
