import { Layout } from "antd";
import React, { useRef } from "react";
import AppSidebarLayout from "./AppSidebar";
import styled from "styled-components";
import SideBar from "./SideMenu";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import COLORS from "../../Common/Colors";
import { DEVICE } from "../../Common/Device";

const { Content } = Layout;

const StyledContent = styled(Content)`
  height: 90vh;
  background: rgb(255, 255, 255);
  padding: 20px 20px 20px 20px;
  overflow-y: auto;

  .ant-layout {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: unset;
    height: 100%;
    background: #f5f5f5;
  }
  .ant-layout.ant-layout-has-sider {
    flex-direction: row;
    // min-height: 100% !important;
  }

  ${({ isloading }) => isloading === "true" && `text-align: center;`}
  .initial-loader {
    margin-top: 100px;
    color: ${COLORS.grey};
  }
  ${({ nopadding }) =>
    nopadding === "true" && `padding: 0px !important; border: none`}

  .ant-layout-content{
    // height: 100vh;
    width: 100%;
    background: rgb(255, 255, 255) !important;
  }
  @media ${DEVICE.mobileS} {
    .ant-picker-calendar-full .ant-picker-content {
      width: 100%;
    }
  }
  @media ${DEVICE.laptopM} {
    .ant-table {
      width: 100%;
    }
    .ant-picker-calendar-full .ant-picker-content {
      width: 100%;
    }
  }
  @media ${DEVICE.laptopL} {
    .ant-picker-calendar-full .ant-picker-content {
      width: 100%;
    }
  }
`;

const MainLayout = () => {
  const layoutRef = useRef(null);
  const sidebarRef = useRef(null);
  return(
    <>
      <Layout ref={layoutRef} isapp={`${navigator.userAgent === "application"}`}>
        <Layout.Sider ref={sidebarRef} width={70} className="">
          <AppSidebarLayout />
        </Layout.Sider>

        <Layout>
          <Header />
          <Layout>
            <SideBar />
            <StyledContent>
              <Outlet />
            </StyledContent>
          </Layout>
        </Layout>
      </Layout>
    </>
  )
}

export default MainLayout;