import React, { useEffect } from "react";
import styled from "styled-components";
import { MenuOutlined } from "@ant-design/icons";
import Icon from "../../reusableComponent/SideNavIcon";
import { useDispatch, useSelector } from "react-redux";
import useSystemNavigation from "../../SysHooks";
import { setIsSideBarCollapsed } from "../../Redux/systemSlice";
import COLORS from "../../Common/Colors";

const StyledAppDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  // bottom: 0;
  color: white;
  width: 70px;
  padding: 2px;
  margin: auto;
  background-color: ${COLORS.sidebar};


  .icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: 10px;
  }
`;

const BoxIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  height: 60px;
  color: #fff;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.active {
    background-color: #1d4174;
    color: #ffffff;
  }
  span {
    line-height: 1.5;
  }
`;

const SideBarToggleIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  height: 60px;
  cursor: pointer;
  color: white;
`;

const AppSidebarLayout = () => {
  const dispatch = useDispatch()
  const menuList = useSelector((state) => state.systemTracking.menuItem);
  useEffect(() => {

  },[menuList])

  const isSideBarCollapsed = useSelector((state) => state.systemTracking.isSideBarCollapsed)
  const { goOtherMenu, goHome } = useSystemNavigation()

  const hasSubMenu = (menu) =>
    menu.subMenu.length > 0 && menu.subMenu.some((subMenu) => subMenu.access);

  const menuListWithSubmenu = menuList?.filter((menu) => {
    return hasSubMenu(menu);
  });

  const isAnyMenuActive = menuListWithSubmenu?.some((menu) => menu.active);

  const isHomeActive = !isAnyMenuActive;

  const handleToggleSideBar = () => {
    dispatch(setIsSideBarCollapsed(!isSideBarCollapsed))
  };

  const handleClickOtherMenu = (menu) => {
    if (isSideBarCollapsed) {
      dispatch(setIsSideBarCollapsed(false))
    } else {
      goOtherMenu(menu)
    }
  }

  const handleClickHome = () => {
    dispatch(setIsSideBarCollapsed(false))
    goHome()
  }

  return (
    <>
      <StyledAppDiv>
        <SideBarToggleIcon onClick={handleToggleSideBar}>
          {/* {isSideBarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} */}
          <MenuOutlined />
        </SideBarToggleIcon>

        <div className="icons">
          {/* <BoxIcon className={`${isHomeActive ? "active" : ""}`} onClick={handleClickHome}>
            <Icon name="home" />
            <span>Home</span>
          </BoxIcon> */}

          {menuListWithSubmenu?.map((menu, index) => (
            <BoxIcon key={index} className={`${menu?.active ? "active" : ""}`} onClick={() => handleClickOtherMenu(menu)}>
              {menu.icon && <Icon name={menu.icon} />}
              <span>{menu.title}</span>
            </BoxIcon>
          ))}
        </div>
      </StyledAppDiv>
    </>
  );
};

export default AppSidebarLayout;
