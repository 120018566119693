import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { setActiveMenuList, setMenuItemData } from "../Redux/systemSlice"

const useSystemNavigation = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const menuList = useSelector((state) => state.systemTracking.menuItem)

  const goOtherMenu = useCallback(
    (item) => {
      const updatedMenuList = menuList.map((menu) => {
        if (menu.key === item.key) {
          return { ...menu, active: true }; // Set the clicked item to active
        }
        return { ...menu, active: false }; // All others inactive
      });

      dispatch(setMenuItemData(updatedMenuList));

      dispatch(setActiveMenuList(item.subMenu));
  
      const firstAccessibleSubMenu = item.subMenu.find((subMenu) => subMenu.access);
  
      if (firstAccessibleSubMenu) {
        navigate(firstAccessibleSubMenu.path);
      } else {
        navigate(item.path); 
      }
    },
    [menuList, dispatch, navigate]
  );
  
  const goHome = useCallback(() => {
    dispatch(
        setMenuItemData(
            menuList.map((menu) => {
            return { ...menu, active: false }
        })
      )
    )
    dispatch(
      setActiveMenuList(
        menuList.filter((menu) => {
          return menu.subMenu.length === 0
        })
      )
    )

    const firstMenu = menuList.find((menu) => {
      return menu.subMenu.length === 0 && menu.access
    })

    navigate(firstMenu?.path || "/")
  }, [menuList, dispatch, navigate])

// Below Commend codes are used for future 

//   const goHomeAfterLogin = useCallback(
//     (menuListData) => {
//       const filterTymplusPay = (item) => item.system === "pay"

//       const payMenuList = menuListData.filter(filterTymplusPay)

//       dispatch(
//         setMenuList(
//           payMenuList.map((menu) => {
//             return { ...menu, active: false }
//           })
//         )
//       )
//       dispatch(
//         setActiveMenuList(
//           payMenuList.filter((menu) => {
//             return menu.subMenu.length === 0
//           })
//         )
//       )

//       const firstMenu = payMenuList.find((menu) => {
//         return menu.subMenu.length === 0 && menu.access
//       })

//       navigate(firstMenu?.path || "/")
//     },
//     [dispatch, navigate]
//   )

  return {
    goOtherMenu,
    goHome,
    // goHomeAfterLogin,
  }
}

export default useSystemNavigation
